import {
  AffiliateSubType,
  Media,
  Product,
  ShoppingType,
  VariantFieldsFragment,
} from '~/gql/generated';
import {
  ProductVariant,
  Product as ShopifyProduct,
} from '@shopify/hydrogen/storefront-api-types';

import {MergedProduct} from '../context/types';
import {Storefront} from '@shopify/hydrogen';
import {getShopifyProductsByIds} from './product.utils.server';

export function mergeProducts(
  products: Product[],
  shopifyProducts: ShopifyProduct[],
) {
  if (products?.length > 0 || shopifyProducts?.length > 0) {
    const shopifyIdMap = shopifyProducts.reduce((res, item) => {
      if (item !== null) {
        res[item.id] = item;
      }
      return res;
    }, {} as Record<string, ShopifyProduct>);

    return (products || [])
      .filter(Boolean)
      .map((product) =>
        createMergedProduct(
          product?.shoppingType === ShoppingType.Synthetic
            ? fillSyntheticProduct(product, shopifyIdMap)
            : product,
          shopifyIdMap[product.externalId],
        ),
      )
      .filter(Boolean);
  }
  return [];
}

export function createMergedProduct(
  product: Product,
  shopifyProduct: ShopifyProduct,
) {
  if (
    ![ShoppingType.Full, ShoppingType.FirstParty].includes(product.shoppingType)
  ) {
    product = addSelectedOptionsToVariant(product);
  }
  if (product.affiliateSubType === AffiliateSubType.Amazon) {
    product.name = simplifiedProductName(
      product.name || shopifyProduct?.title || '',
      product.brandName,
    );
  }
  const selectedVariant = getSelectedVariant(product, shopifyProduct);
  const variants =
    (shopifyProduct ? shopifyProduct.variants?.nodes : product.variants) || [];
  const res = {
    ...product,
    userMedia: getUserMedia(product.media || []),
    selectedVariant,
  };

  return shopifyProduct
    ? {
        ...res,
        brandDescription: shopifyProduct.descriptionHtml || '',
        price:
          selectedVariant?.price?.amount || variants?.[0]?.price?.amount || 0,
        variants:
          variants?.map(fillVariantFromShopifyVariant).filter(Boolean) || [],
        media: mergeMedia(shopifyProduct, product.media || []),
        brand: shopifyProduct,
      }
    : ![ShoppingType.Full, ShoppingType.FirstParty].includes(
        product.shoppingType,
      )
    ? {
        ...res,
        price: selectedVariant?.price || res.price,
        options: createProductOptions(product),
      }
    : null;
}

export function formatPrice(
  price: number | string,
  decimal?: number,
  roundZeros?: boolean,
) {
  price = typeof price === 'string' ? parseFloat(price) : price;
  const places = typeof decimal === 'number' ? decimal : 2;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: places,
    minimumFractionDigits: roundZeros ? 0 : places,
  });

  const formatted = formatter.format(price);

  return isNaN(price) ? '' : formatted;
}

export function formatDollars(
  price: number | string,
  decimal?: number,
  roundZeros?: boolean,
) {
  price = typeof price === 'string' ? parseFloat(price) : price;
  const places = typeof decimal === 'number' ? decimal : 2;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: places,
    minimumFractionDigits: roundZeros ? 0 : places,
  });
  price = isNaN(price) ? 0 : price;

  return formatter.format(price);
}

export function formatDiscountValue(discountValue: string) {
  const value = parseFloat(discountValue || '');
  return value % 1 === 0 ? value : value.toFixed(2);
}

function getShopifyMedia(shopifyProduct: ShopifyProduct) {
  if (!shopifyProduct) {
    return [];
  }

  return Object.values(
    (shopifyProduct.media?.nodes || [])
      .concat(shopifyProduct?.variants?.nodes || [])
      .reduce((res, variant) => {
        if (variant.image) {
          res[variant.image.url] = {
            src: variant.image.url,
            alt: variant.image.altText || '',
            id: variant.image.id || '',
            mediaProductId: variant.image?.id || '',
          };
        } else if (
          variant.mediaContentType === 'VIDEO' &&
          variant.sources?.[0]?.url
        ) {
          res[variant.sources?.[0]?.url] = {
            src: variant.sources?.[0]?.url,
            alt: '',
            id: variant.id || '',
            mediaProductId: variant?.id || '',
          };
        }

        return res;
      }, {}),
  );
}

function sortMedia(shopifyMedia: Media[], media: Media[]) {
  return [shopifyMedia[0], ...media, ...shopifyMedia.slice(1)].filter(Boolean);
}

function mergeMedia(shopifyProduct: ShopifyProduct, media: Media[]) {
  return sortMedia(getShopifyMedia(shopifyProduct), media);
}

function getSelectedVariant(product: Product, shopifyProduct?: ShopifyProduct) {
  return (
    shopifyProduct?.selectedVariant ||
    shopifyProduct?.variants?.nodes[0] ||
    product.selectedVariant || // pdp will append a selected variant based on availability
    product.variants?.[0]
  );
}

function createProductOptions(
  product: Product,
): {key: string; values: string[]}[] {
  // run over the product variants to extract options by value
  // output should be an array of options with values
  const optionsMap = (product.variants || []).reduce((res, variant) => {
    (variant?.options || []).forEach(({key, value}) => {
      if (!res[key]) {
        res[key] = new Set();
      }
      res[key].add(value);
    });
    return res;
  }, {} as Record<string, Set<string>>);

  return Object.keys(optionsMap).map((key) => ({
    key,
    values: Array.from(optionsMap[key]),
  }));
}

function addSelectedOptionsToVariant(product: Product) {
  return {
    ...product,
    variants: (product.variants || []).map((variant) => ({
      ...variant,
      selectedOptions: getProductSelectedOptions(variant),
    })),
    selectedVariant: product.selectedVariant
      ? {
          ...product.selectedVariant,
          selectedOptions: getProductSelectedOptions(product.selectedVariant),
        }
      : product.selectedVariant,
  };
}

function getProductSelectedOptions(variant): {name: string; value: string}[] {
  return (variant?.options || []).map(({key, value}) => ({
    name: key,
    value,
  }));
}

const getUserMedia = (media) =>
  media.filter(({mediaProductId}) => !mediaProductId);

export const getIsValidShopifyExternalId = (id: string) =>
  id && id.includes('shopify/');

export function mapProductValidShopifyIds(products: Product[]) {
  return Array.from(
    (products || []).reduce((acc, p) => {
      if (p.shoppingType === ShoppingType.Synthetic) {
        (p.variants || []).forEach((variant) => {
          if (variant) {
            acc.add(variant.externalId);
          }
        });
      } else {
        if (p) {
          acc.add(p.externalId);
        }
      }
      return acc;
    }, new Set<string>()),
  ).filter(getIsValidShopifyExternalId);
}

export function sliceUnusedShopifyMedia(products: ShopifyProduct[]) {
  return (products || []).map((product) => {
    return {
      ...product,
      media: {nodes: (product.media.nodes || []).slice(0, 1)},
    };
  });
}

export function sliceUnusedProductMedia(products: Product[]) {
  return (products || []).map((product) => {
    return {
      ...product,
      media: (product.media || []).slice(0, 1),
    };
  });
}

export function fillSyntheticProduct(
  product: Product,
  shopifyIdMap: Record<string, ShopifyProduct>,
) {
  const variants = (product?.variants || []).map((variant) => {
    if (!variant || variant._isFilled) return variant;
    const shopifyProduct = shopifyIdMap[variant.externalId];
    if (shopifyProduct && shopifyProduct.variants?.nodes?.[0]) {
      return fillVariantFromShopifyVariant(
        shopifyProduct.variants.nodes[0],
        variant,
      );
    }
  });

  const media = (product?.variants || [])
    .filter(Boolean)
    .map((variant) => getShopifyMedia(shopifyIdMap[variant.externalId]))
    .flat()
    .filter(Boolean);

  return {
    ...product,
    media: sortMedia(media, product.media || []),
    variants,
  };
}

function fillVariantFromShopifyVariant(
  variant: ProductVariant,
  baseVariant?: VariantFieldsFragment,
) {
  if (!variant) return null;
  if (variant._isFilled) return variant;

  return {
    ...(baseVariant || {}),
    id: variant.id || '',
    externalId: variant.id,
    optionKey: variant.product?.handle || '',
    title: variant.product?.title || '',
    outlink: '',
    price: variant.price?.amount,
    media: {
      src: variant.image?.url || '',
      alt: variant.image?.altText || '',
      id: variant.image?.id || '',
    },
    sku: variant.sku,
    availableForSale: variant.availableForSale,
    _isFilled: true,
  };
}

export async function fillSyntheticProductApi(
  product: Product,
  storefront: Storefront,
) {
  const shopifyIds = Array.from(
    (product?.variants || []).reduce((res, {externalId}) => {
      res.add(externalId);
      return res;
    }, new Set<string>()),
  );

  const shopifyProducts = await getShopifyProductsByIds(shopifyIds, storefront);
  const media = (product?.variants || [])
    .map((variant) =>
      getShopifyMedia(
        findShopifyProductById(shopifyProducts, variant.externalId),
      ),
    )
    .flat()
    .filter(Boolean);

  // todo - cleaner way for this to be done... selectedVariant description
  let description = '';
  const variants = product?.variants?.map((variant) => {
    const shopifyProduct = findShopifyProductById(
      shopifyProducts,
      variant?.externalId,
    );
    description = !description.length
      ? shopifyProduct?.descriptionHtml || ''
      : description;

    return fillVariantFromShopifyVariant(
      shopifyProduct?.variants?.nodes?.[0],
      variant,
    );
  });

  return {
    ...product,
    variants,
    brandDescription: description,
    media: sortMedia(media, product?.media || []),
  };
}

function findShopifyProductById(
  shopifyProducts: {nodes: ShopifyProduct[]},
  id: string,
) {
  return shopifyProducts?.nodes?.find(
    (shopifyProduct) => shopifyProduct.id === id,
  );
}

export function filterProductsByVisibility(products: Product[]) {
  return (products || []).filter((product) => product.isVisibleInStore);
}

export function replaceAllNbsp(content: string): string {
  return content.replace(/&nbsp;/g, ' ').trim() || '';
}

export function splitProductName(content: string) {
  const delimiters = [', ', ' | ', ' - ', ' \u2013 ', '- ', ' with '];
  return delimiters.reduce((name, delimiter) => {
    const splitName = name.split(delimiter);
    if (splitName[0]?.length > 10 || !splitName[1]) {
      return splitName[0];
    }
    return splitName[1];
  }, replaceAllNbsp(content));
}

/*
 * Removes the maker from the product name and truncates the product name
 */
export function simplifiedProductName(
  productName: string,
  maker: string,
  keepBrandName?: boolean,
) {
  const len = (productName || '').length;
  if (!keepBrandName) {
    productName = (productName || '').replace(maker, '').trim();
  }
  if (len <= 60) {
    return productName;
  }
  return splitProductName(productName);
}

export function isProductIngested(product: MergedProduct) {
  return (
    (product && product?.id?.toLocaleLowerCase() !== 'not_ingested') || false
  );
}
